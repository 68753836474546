import type { FC } from 'react';
import React, { memo, Fragment } from 'react';

import Tooltip from '@atlaskit/tooltip/Tooltip';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	useKeyboardShortcutsState,
} from '@confluence/shortcuts';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';

export type KeyboardShortcutWrapperProps = {
	href?: string;
	keyboardShortcut?: string;
	isAppNavigationShortcut?: boolean;
	onShortcutTriggered: Function;
};

export const KeyboardShortcutWrapper: FC<KeyboardShortcutWrapperProps> = memo(
	({ href, keyboardShortcut, isAppNavigationShortcut, children, onShortcutTriggered }) => {
		const { push } = useRouteActions();

		const [areKeyboardShortcutsEnabled] = useKeyboardShortcutsState();

		const listenerCallback = () => {
			onShortcutTriggered();
			href && push(href);
		};

		return (
			<Fragment>
				{!!keyboardShortcut && areKeyboardShortcutsEnabled ? (
					<Tooltip content={<ShortcutVisualizer shortcut={keyboardShortcut} />}>{children}</Tooltip>
				) : (
					children
				)}
				{keyboardShortcut && (
					<GeneralShortcutListener
						accelerator={keyboardShortcut}
						listener={listenerCallback}
						isAppNavigationShortcut={isAppNavigationShortcut || false}
					/>
				)}
			</Fragment>
		);
	},
);
